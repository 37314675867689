import React, { useState } from "react";
import { useI18next } from "gatsby-plugin-react-i18next";
import Layout from "../components/layout";
import {
  getAlternateLang,
  professionOptionsEn,
  professionOptionsEs,
} from "../utils/alternate-lang";
import { graphql, navigate } from "gatsby";
import PrivateRoute from "../components/private-route";
import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { getUser, setUserFromUpdateData } from "../services/auth";
import SelectCountryEs from "../components/lists/select-country-es";
import { useTheme } from "@mui/styles";
import SelectCountryEn from "../components/lists/select-country-en";
import { gql, useMutation } from "@apollo/client";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { buildTherapeuticAreasOptions } from "../utils/therapeutic-areas";

export const query = graphql`
  query ProfileEdit($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    therapeuticAreaEn: allStrapiTherapeuticalArea(
      filter: { locale: { eq: "en" } }
    ) {
      edges {
        node {
          Name
          Slug
          AlternateSlug
          color
        }
      }
    }
    therapeuticAreaEs: allStrapiTherapeuticalArea(
      filter: { locale: { eq: "es-PA" } }
    ) {
      edges {
        node {
          Name
          Slug
          AlternateSlug
          color
        }
      }
    }
  }
`;

const ProfileEditComponent = ({ data }) => {
  const { t, language } = useI18next();
  const theme = useTheme();

  const UPDATE_PROFILE = gql`
    mutation (
      $id: ID!
      $userLastName: String!
      $userFirstName: String!
      $profession: String!
      $country: String!
      $areasOfExpertise: String
      $favoriteLanguage: String
    ) {
      updateUser(
        input: {
          where: { id: $id }
          data: {
            userLastName: $userLastName
            userFirstName: $userFirstName
            country: $country
            profession: $profession
            areasOfExpertise: $areasOfExpertise
            favoriteLanguage: $favoriteLanguage
          }
        }
      ) {
        user {
          id
          userLastName
          userFirstName
          country
          profession
          areasOfExpertise
          email
          progress
          quizzResults
          subscribedEvents
          subscribedNewsletter
          favoriteLanguage
        }
      }
    }
  `;

  const [updateUser, { loading: loadingUpdateUser }] =
    useMutation(UPDATE_PROFILE);
  const onClickProfile = (event) => {
    event.preventDefault();
    navigate(`/${language === "en" ? "en" : "es"}/profile`);
  };
  const onClickResetPassword = (event) => {
    event.preventDefault();
    navigate(`/${language === "en" ? "en" : "es"}/forgotten-password`, {
      state: { prevPath: "/profile-edit" },
    });
  };
  const handleUpdate = (event) => {
    event.preventDefault();
    updateUser({
      variables: {
        id: values.id,
        userLastName: values.userLastName,
        userFirstName: values.userFirstName,
        country: values.country,
        profession: values.profession,
        areasOfExpertise:
          // We store the EN Slug of the therapeutic area
          JSON.stringify(values.areasOfExpertise),
        favoriteLanguage: language,
      },
      headers: {
        Authorization: `Bearer ${values.token}`,
      },
    })
      .then((data) => {
        // Handle success.
        setUserFromUpdateData(data, getUser().token);
        navigate(`/${language === "en" ? "en" : "es"}/profile`, {
          state: { showAlert: true },
        });
      })
      .catch((error) => {
        // Handle error.
        console.log("profile edition : an error occurred:", error?.response);
      });

    return false;
  };
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const [therapeuticAreaOptions, setTherapeuticAreaOptions] = useState(
    buildTherapeuticAreasOptions(
      data.therapeuticAreaEn.edges,
      data.therapeuticAreaEs.edges
    )
  );

  const [values, setValues] = React.useState({
    password: getUser().password,
    userLastName: getUser().userLastName,
    userFirstName: getUser().userFirstName,
    showPassword: false,
    country: getUser().country,
    profession: getUser().profession,
    areasOfExpertise: getUser().areasOfExpertise
      ? JSON.parse(getUser().areasOfExpertise)
      : "",
    id: getUser().id,
  });

  const handleChangeAreas = (event) => {
    const {
      target: { value },
    } = event;
    const newValues = typeof value === "string" ? value.split(",") : value;
    if (newValues.length > 1 && newValues.includes("other")) {
      setValues({
        ...values,
        areasOfExpertise: newValues.filter((area) => "other" !== area),
      });
    } else {
      setValues({
        ...values,
        areasOfExpertise: newValues,
      });
    }
  };

  const getRenderAreas = (selected) => {
    if (
      !selected ||
      selected.length === 0 ||
      (selected.length === 1 && "other" === selected[0])
    ) {
      return t("other");
    }
    return therapeuticAreaOptions
      .filter((item) => selected.includes(item.id))
      .map((item) => (language === "en" ? item.nameEn : item.nameEs))
      .join(", ");
  };

  const seo = {
    metaTitle: t("profile.edition"),
    metaDescription: t("profile.edition").toLocaleLowerCase(),
    linkAlternateHref: "/" + getAlternateLang(language) + "/profile-edit",
    linkHrefLang: getAlternateLang(language),
    noindex: true,
  };

  const professionOptions =
    language === "en" ? professionOptionsEn : professionOptionsEs;
  return (
    <Layout seo={seo} textAlign="center">
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          py: 4,
          px: 2,
          gap: 8,
        }}
      >
        <Typography variant="h1">{t("profile.edition")}</Typography>
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            py: 2,
            px: 0,
            gap: 8,
          }}
        >
          <FormControl
            sx={{ maxWidth: "35ch", width: "100%" }}
            variant="standard"
          >
            <InputLabel htmlFor="email">{t("register.firstName")}</InputLabel>
            <Input
              id="userFirstName"
              value={values.userFirstName}
              onChange={handleChange("userFirstName")}
            />
          </FormControl>
          <FormControl
            sx={{ maxWidth: "35ch", width: "100%" }}
            variant="standard"
          >
            <InputLabel htmlFor="email">{t("register.lastName")}</InputLabel>
            <Input
              id="userLastName"
              value={values.userLastName}
              onChange={handleChange("userLastName")}
            />
          </FormControl>
          <FormControl
            sx={{ maxWidth: "35ch", width: "100%" }}
            variant="standard"
          >
            <InputLabel id="filter-content-label">
              {t("register.country")}
            </InputLabel>
            {language === "en" ? (
              <SelectCountryEn
                country={values.country}
                handleChange={handleChange("country")}
              />
            ) : (
              <SelectCountryEs
                country={values.country}
                handleChange={handleChange("country")}
              />
            )}
          </FormControl>
          <FormControl
            sx={{ maxWidth: "35ch", width: "100%" }}
            variant="standard"
          >
            <InputLabel id="filter-content-label">
              {t("register.areasOfExpertise")}
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={
                values.areasOfExpertise?.length === 0
                  ? ["other"]
                  : values.areasOfExpertise
              }
              onChange={handleChangeAreas}
              renderValue={getRenderAreas}
            >
              {therapeuticAreaOptions.map((th) => (
                <MenuItem key={th.id} value={th.id}>
                  <Checkbox
                    checked={
                      !!values.areasOfExpertise.find((item) => th.id === item)
                    }
                  />
                  <ListItemText
                    primary={language === "en" ? th.nameEn : th.nameEs}
                  />
                </MenuItem>
              ))}
              <MenuItem key={"other"} value={"other"}>
                <Checkbox
                  checked={
                    values.areasOfExpertise.length === 0 ||
                    !!values.areasOfExpertise.find((item) => "other" === item)
                  }
                />
                <ListItemText primary={t("other")} />
              </MenuItem>
            </Select>
            <FormHelperText>{t("register.areasAdvice")}</FormHelperText>
          </FormControl>
          <FormControl
            sx={{ maxWidth: "35ch", width: "100%" }}
            variant="standard"
          >
            <InputLabel id="filter-content-label">
              {t("register.profession")}
            </InputLabel>
            <Select
              value={values.profession}
              onChange={handleChange("profession")}
            >
              {professionOptions
                .sort((item1, item2) =>
                  item1.content.localeCompare(item2.content)
                )
                .map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.content}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Container>
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            px: 0,
          }}
        >
          <Button
            style={{
              letterSpacing: "0.1em",
              marginBottom: "1em",
              borderTop: "solid",
              borderBottom: "solid",
              borderTopWidth: "thin",
              borderBottomWidth: "thin",
              borderTopColor: "#BDBDBD",
              borderBottomColor: "#BDBDBD",
              borderRadius: 0,
              color: "black",
              textTransform: "none",
            }}
            sx={{
              maxWidth: "40ch",
              width: "100%",
              justifyContent: "space-between",
              py: 2,
            }}
            onClick={onClickResetPassword}
          >
            <Typography variant="subtitle1">
              {t("profile.resetPassword")}
            </Typography>
            <ArrowForwardIosIcon style={{ width: "18px" }} />
          </Button>
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 4,
              mx: 0,
              px: 0,
            }}
          >
            <Button
              variant="contained"
              style={{
                letterSpacing: "0.1em",
                backgroundColor: theme.palette.primary.main,
              }}
              sx={{ maxWidth: "40ch", width: "100%" }}
              onClick={handleUpdate}
            >
              {t("profile.save")}
            </Button>
            <Button
              style={{
                letterSpacing: "0.1em",
              }}
              sx={{ width: "40ch" }}
              onClick={onClickProfile}
            >
              {t("profile.cancel")}
            </Button>
          </Container>
        </Container>
      </Container>
    </Layout>
  );
};
const ProfileEdit = ({ data }) => {
  return <PrivateRoute component={ProfileEditComponent} data={data} />;
};
export default ProfileEdit;
